// React Basic and Bootstrap
import React, { Component } from 'react';

// Import Generic Components
import Section from './section';
import Partners from './Partners';
import HowItWorks from './HowItWorks';
import Testi from './Testi';
import Pricing from './Pricing';
import Faq from './Faq';
import Popup from '../../components/Layout/popup';
import { Container, Row } from 'reactstrap';

class IndexMain extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.scrollNavigation = this.scrollNavigation.bind(this);
  }

  componentDidMount() {
    document.body.classList = '';
    document.querySelector('.shoppingbtn').classList.add('btn-primary');
    window.addEventListener('scroll', this.scrollNavigation, true);
  }

  // Make sure to remove the DOM listener when the component is unmounted.
  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var doc = document.documentElement;
    const navBar = document.getElementById('topnav');
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (navBar != null) {
      if (top > 80) {
        navBar.classList.add('nav-sticky');
      } else {
        navBar.classList.remove('nav-sticky');
        document.querySelector('.shoppingbtn').classList.add('btn-primary');
        document.querySelector('.shoppingbtn').classList.remove('btn-light');
        document.querySelector('.settingbtn').classList.add('btn-soft-primary');
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        <section className="bg-half-170 d-table w-100" id="home">
          <Container>
            <Row className="mt-5 align-items-center">
              <h1>CONDITIONS GENERALES DE VENTE ET D&apos;UTILISATION</h1>
              <p>
                <br />
              </p>
              <p>----</p>
              <p>
                <br />
              </p>
              <p>APER&Ccedil;U</p>
              <p>
                <br />
              </p>
              <p>
                Ce site web est exploit&eacute; par ResellCorner. Partout sur le
                site, nous employons les termes &laquo;&nbsp;nous&nbsp;&raquo;,
                &laquo;&nbsp;notre&nbsp;&raquo; et &laquo;&nbsp;nos&nbsp;&raquo;
                en r&eacute;f&eacute;rence &agrave; ResellCorner. Ce site web, y
                compris l&apos;ensemble des informations, outils et services
                auquel il donne acc&egrave;s, est offert par ResellCorner
                &agrave; l&apos;utilisateur que vous &ecirc;tes, &agrave;
                condition que vous acceptiez la totalit&eacute; des
                modalit&eacute;s, conditions, politiques et avis stipul&eacute;s
                ici.
              </p>
              <p>
                <br />
              </p>
              <p>
                En visitant notre site et/ou en achetant quelque chose
                aupr&egrave;s de notre entreprise, vous prenez part &agrave;
                notre &laquo;&nbsp;Service&nbsp;&raquo; et acceptez
                d&apos;&ecirc;tre li&eacute;(e) par les modalit&eacute;s et
                conditions suivantes (&laquo;&nbsp;Conditions
                g&eacute;n&eacute;rales&nbsp;&raquo;, &laquo;&nbsp;Conditions
                d&apos;utilisation&nbsp;&raquo;), y compris par les
                modalit&eacute;s, conditions et politiques mentionn&eacute;es
                aux pr&eacute;sentes et/ou accessibles en hyperlien. Les
                pr&eacute;sentes Conditions d&apos;utilisation s&apos;appliquent
                &agrave; tous les utilisateurs du Site, y compris, sans s&apos;y
                limiter, aux individus qui sont des visiteurs, des fournisseurs,
                des clients, des marchands et/ou des fournisseurs de contenu.
              </p>
              <p>
                Veuillez lire attentivement les pr&eacute;sentes Conditions
                d&apos;utilisation avant d&apos;acc&eacute;der &agrave; notre
                site web et de l&apos;utiliser. En acc&eacute;dant &agrave; une
                quelconque partie du Site ou en l&apos;utilisant, vous acceptez
                d&apos;&ecirc;tre li&eacute;(e) par les pr&eacute;sentes
                Conditions d&apos;utilisation. Si vous n&apos;acceptez pas la
                totalit&eacute; des modalit&eacute;s et conditions du
                pr&eacute;sent accord, vous ne pourrez peut-&ecirc;tre pas
                acc&eacute;der au site web ou utiliser ses services. Si les
                pr&eacute;sentes Conditions d&apos;utilisation sont
                consid&eacute;r&eacute;es comme une offre, leur acceptation se
                limite express&eacute;ment &agrave; elles.
              </p>
              <p>
                <br />
              </p>
              <p>
                Chacun des nouveaux outils ou fonctionnalit&eacute;s qui sont
                ajout&eacute;s au pr&eacute;sent site est &eacute;galement
                assujetti aux Conditions d&apos;utilisation. Vous pouvez
                consulter la version la plus r&eacute;cente des Conditions
                d&apos;utilisation &agrave; tout moment sur cette page. Nous
                nous r&eacute;servons le droit de mettre &agrave; jour, modifier
                ou remplacer n&apos;importe quelle partie des pr&eacute;sentes
                Conditions d&apos;utilisation en publiant lesdites mises
                &agrave; jour et/ou modifications sur notre site web. Il vous
                incombe de v&eacute;rifier cette page de temps &agrave; autre
                pour voir si des changements y ont &eacute;t&eacute;
                apport&eacute;s. En continuant &agrave; acc&eacute;der au site
                web ou &agrave; l&apos;utiliser apr&egrave;s la publication des
                modifications, vous acceptez celles-ci.
              </p>
              <p>Notre site est h&eacute;berg&eacute;e sur Godaddy.&nbsp;</p>
              <p>
                <br />
              </p>
              <p>
                SECTION&nbsp;1 &ndash; CONDITIONS D&apos;UTILISATION DU SITE.
              </p>
              <p>
                <br />
              </p>
              <p>
                En acceptant les pr&eacute;sentes Conditions d&apos;utilisation,
                vous d&eacute;clarez avoir atteint ou d&eacute;pass&eacute;
                l&apos;&acirc;ge de la majorit&eacute; dans votre r&eacute;gion,
                province ou &Eacute;tat et nous avoir donn&eacute;
                l&apos;autorisation de permettre &agrave; toute personne mineure
                &agrave; votre charge d&apos;utiliser ce site.
              </p>
              <p>
                <br />
              </p>
              <p>
                Vous ne devez en aucune fa&ccedil;on utiliser nos produits
                &agrave; des fins ill&eacute;gales ou non autoris&eacute;es, ni
                violer des lois de votre juridiction lorsque vous utilisez le
                Service (y compris, sans toutefois s&apos;y limiter, les lois
                relatives aux droits d&apos;auteur).
              </p>
              <p>
                <br />
              </p>
              <p>
                Vous ne devez pas transmettre de vers informatique, de virus ou
                tout code de nature destructrice.
              </p>
              <p>
                <br />
              </p>
              <p>
                Une infraction ou une violation de n&apos;importe laquelle des
                Conditions entra&icirc;nera la r&eacute;siliation
                imm&eacute;diate de vos Services.
              </p>
              <p>
                Il est formellement interdit de revendre, redistribuer ou
                exploiter &agrave; des fins commerciales les r&eacute;sultats,
                donn&eacute;es, informations ou tout contenu
                g&eacute;n&eacute;r&eacute; par Resell Corner sans
                l&apos;autorisation expresse et &eacute;crite de notre
                soci&eacute;t&eacute;.
              </p>
              <p>
                De plus, tout usage de m&eacute;thodes automatis&eacute;es,
                telles que le scrapping, l&apos;agr&eacute;gation, le crawling
                ou toute autre technique similaire visant &agrave; collecter,
                extraire ou dupliquer des informations depuis Resell Corner est
                strictement prohib&eacute;. Toute tentative de
                r&eacute;cup&eacute;ration automatis&eacute;e de donn&eacute;es
                ou d&apos;informations, que ce soit pour un usage commercial ou
                non, est express&eacute;ment interdite et peut entra&icirc;ner
                des mesures l&eacute;gales.
              </p>
              <p>
                Nous nous r&eacute;servons le droit d&apos;appliquer des mesures
                disciplinaires et l&eacute;gales, y compris mais sans s&apos;y
                limiter, la suspension ou la r&eacute;siliation du compte, ainsi
                que des poursuites judiciaires pour toute violation de ces
                conditions.
              </p>
              <p>
                En utilisant Resell Corner, vous acceptez de respecter ces
                conditions et de ne pas utiliser de m&eacute;thodes
                automatis&eacute;es ou de revendre les r&eacute;sultats ou
                informations obtenus via notre plateforme sans notre
                consentement &eacute;crit explicite.
              </p>
              <p>SECTION 1.1 &ndash; INTERDICTION DE PARTAGE DE COMPTE</p>
              <p>
                Il est strictement interdit de partager vos identifiants de
                compte Resell Corner avec d&apos;autres utilisateurs ou
                entit&eacute;s. Votre compte est personnel et confidentiel, et
                sa divulgation &agrave; des tiers est express&eacute;ment
                proscrite.
              </p>
              <p>
                Chaque compte utilisateur est destin&eacute; &agrave; &ecirc;tre
                utilis&eacute; par une seule personne. Tout partage,
                distribution ou acc&egrave;s simultan&eacute; au compte par
                plusieurs individus est contraire &agrave; nos conditions
                d&apos;utilisation.
              </p>
              <p>
                Nous nous r&eacute;servons le droit de prendre des mesures
                disciplinaires, y compris la suspension ou la r&eacute;siliation
                imm&eacute;diate du compte, en cas de constatation de partage de
                compte ou d&apos;acc&egrave;s non autoris&eacute;.
              </p>
              <p>
                En utilisant Resell Corner, vous consentez &agrave; ne pas
                partager vos identifiants de compte avec d&apos;autres personnes
                et &agrave; pr&eacute;server la confidentialit&eacute; de votre
                acc&egrave;s &agrave; notre plateforme.
              </p>
              <p>
                <br />
              </p>
              <p>SECTION 1.2 &ndash; SURVEILLANCE ET APPLICATION</p>
              <p>
                <br />
              </p>
              <p>
                Nous nous r&eacute;servons le droit d&apos;enqu&ecirc;ter sur
                toute violation de ces conditions d&apos;utilisation ou de tout
                abus de notre outil Resell Corner, y compris la suppression, la
                d&eacute;sactivation de l&apos;acc&egrave;s ou la modification
                de tout contenu en violation de ces conditions.
              </p>
              <p>
                <br />
              </p>
                  <p>SECTION 1.3 &ndash;  Utilisation de l'Extension Chrome</p>
              <p>
                <br />
              </p>
              <p>
                Resell Corner propose une extension Chrome qui permet aux utilisateurs de connecter leur compte vendeur directement à notre plateforme. En installant et en utilisant cette extension, vous acceptez de nous fournir un access token pour le compte vendeur. Ce token est nécessaire au bon fonctionnement de l'extension et nous permet d'améliorer la fréquence de mise à jour des informations liées à votre compte sur Resell Corner, optimisant ainsi la précision et la rapidité de nos services.

Il est important de noter que cette extension ne nous donne pas accès à vos données personnelles. L'accès est strictement limité à la mise à jour des informations produits, et aucune autre type de donnée n'est collecté ni utilisé à d'autres fins.
              </p>
              <p>
                <br />
              </p>
              <p>SECTION&nbsp;2 &ndash; CONDITIONS G&Eacute;N&Eacute;RALES</p>
              <p>
                <br />
              </p>
              <p>
                Nous nous r&eacute;servons le droit de refuser de servir
                quelqu&apos;un &agrave; tout moment et pour quelque raison que
                ce soit.
              </p>
              <p>
                <br />
              </p>
              <p>
                Vous acceptez que, dans le cadre de la maintenance du
                syst&egrave;me, notre outil Resell Corner puisse
                occasionnellement cesser de fournir un service ou une
                fonctionnalit&eacute; pour une p&eacute;riode
                ind&eacute;termin&eacute;e, sans pr&eacute;avis ni notification
                pr&eacute;alable. Vous convenez que votre acc&egrave;s &agrave;
                Resell Corner et &agrave; certaines fonctionnalit&eacute;s peut
                &ecirc;tre interrompu ou suspendu pour des raisons, notamment
                des d&eacute;faillances d&apos;&eacute;quipement, des mises
                &agrave; jour p&eacute;riodiques du syst&egrave;me, des
                op&eacute;rations de maintenance ou d&apos;autres actions que
                nous pourrions choisir d&apos;entreprendre. Vous reconnaissez
                que vous n&apos;avez pas droit &agrave; des remboursements, des
                indemnisations ou toute autre forme de compensation en cas de
                perturbations du service.
              </p>
              <p>
                <br />
              </p>
              <p>
                Vous comprenez que votre contenu (&agrave; l&apos;exception des
                informations relatives &agrave; votre carte de cr&eacute;dit)
                peut &ecirc;tre transf&eacute;r&eacute; sans chiffrement et que
                cela comprend (a) des transmissions sur plusieurs
                r&eacute;seaux&nbsp;; et (b) des changements effectu&eacute;s
                dans le but de se conformer et de s&apos;adapter aux exigences
                techniques de la connexion de r&eacute;seaux ou
                d&apos;appareils. Les informations de votre carte de
                cr&eacute;dit sont toujours chiffr&eacute;es lors de leur
                transfert sur les r&eacute;seaux.
              </p>
              <p>
                <br />
              </p>
              <p>
                Vous acceptez de ne pas reproduire, dupliquer, copier, vendre,
                revendre ou exploiter toute partie du Service, toute utilisation
                du Service ou tout acc&egrave;s au Service, ou encore tout
                contact sur le site web &agrave; travers lequel le Service est
                fourni, sans notre autorisation &eacute;crite expresse.
              </p>
              <p>
                <br />
              </p>
              <p>
                Les titres utilis&eacute;s dans le pr&eacute;sent accord sont
                inclus &agrave; titre indicatif uniquement et ne limiteront ni
                n&apos;affecteront aucunement ces Conditions.
              </p>
              <p>
                <br />
              </p>
              <p>
                SECTION&nbsp;3 &ndash; EXACTITUDE, EXHAUSTIVIT&Eacute; ET
                ACTUALIT&Eacute; DES INFORMATIONS
              </p>
              <p>
                <br />
              </p>
              <p>
                Nous ne saurions &ecirc;tre tenus responsables si les
                informations propos&eacute;es sur ce site sont inexactes,
                incompl&egrave;tes ou caduques. Le contenu de ce site est fourni
                &agrave; titre d&apos;information g&eacute;n&eacute;rale
                uniquement et ne doit pas &ecirc;tre consid&eacute;r&eacute; ou
                utilis&eacute; comme seule base pour la prise de
                d&eacute;cisions sans consulter des sources d&apos;information
                plus importantes, plus exactes, plus compl&egrave;tes ou plus
                actuelles. Si vous vous fiez au contenu de ce site, vous le
                faites &agrave; vos propres risques.
              </p>
              <p>
                <br />
              </p>
              <p>
                Ce site peut contenir certaines donn&eacute;es historiques. Par
                d&eacute;finition, les donn&eacute;es historiques ne sont pas
                actuelles et sont fournies uniquement &agrave; titre de
                r&eacute;f&eacute;rence. Nous nous r&eacute;servons le droit de
                modifier les contenus de ce site &agrave; tout moment, mais nous
                n&apos;avons aucune obligation de mettre &agrave; jour les
                informations qu&apos;il contient, quelles qu&apos;elles soient.
                Vous reconnaissez qu&apos;il vous incombe de surveiller les
                changements apport&eacute;s &agrave; notre site.
              </p>
              <p>
                <br />
              </p>
              <p>SECTION&nbsp;4 &ndash; MODIFICATIONS DU SERVICE ET DES PRIX</p>
              <p>
                <br />
              </p>
              <p>
                Les prix de nos produits sont modifiables sans pr&eacute;avis.
              </p>
              <p>
                <br />
              </p>
              <p>
                Nous nous r&eacute;servons le droit de modifier ou de mettre fin
                au Service (ou &agrave; une quelconque partie de celui-ci)
                &agrave; tout moment et sans pr&eacute;avis.
              </p>
              <p>
                <br />
              </p>
              <p>
                Nous ne pourrons &ecirc;tre tenus responsables envers vous ou
                tout tiers de tout changement de prix, ou encore de toute
                modification, suspension ou interruption du Service.
              </p>
              <p>
                <br />
              </p>
              <p>
                SECTION&nbsp;5 &ndash; PRODUITS OU SERVICES (le cas
                &eacute;ch&eacute;ant)
              </p>
              <p>
                <br />
              </p>
              <p>
                Nous nous r&eacute;servons le droit, sans toutefois y &ecirc;tre
                oblig&eacute;s, de limiter la vente de nos produits ou Services
                &agrave; n&apos;importe quelle personne, r&eacute;gion
                g&eacute;ographique ou juridiction donn&eacute;e. Nous nous
                autorisons &agrave; exercer ce droit au cas par cas. Nous nous
                r&eacute;servons le droit de limiter les quantit&eacute;s des
                produits ou services que nous offrons. Toutes les descriptions
                des produits et leur tarification sont modifiables &agrave; tout
                moment, sans pr&eacute;avis et &agrave; notre enti&egrave;re
                discr&eacute;tion. Nous nous r&eacute;servons le droit
                d&apos;interrompre la vente d&apos;un produit &agrave; tout
                moment. Toute offre de produit ou service sur ce site est nulle
                l&agrave; o&ugrave; la loi l&apos;interdit.
              </p>
              <p>
                <br />
              </p>
              <p>
                Nous ne garantissons pas que la qualit&eacute; des produits,
                services, informations ou autres mat&eacute;riels que vous
                achetez ou que vous vous procurez r&eacute;pondra &agrave; vos
                attentes ni que les erreurs que comporte &eacute;ventuellement
                le Service seront corrig&eacute;es.
              </p>
              <p>
                <br />
              </p>
              <p>
                Tout mat&eacute;riel t&eacute;l&eacute;charg&eacute; depuis ou
                obtenu gr&acirc;ce &agrave; l&apos;utilisation du Service est
                effectu&eacute; &agrave; votre seule discr&eacute;tion et
                &agrave; vos propres risques. Vous convenez que vous &ecirc;tes
                seul responsable de tout dommage caus&eacute; &agrave; votre
                syst&egrave;me informatique ou &agrave; d&apos;autres appareils,
                et/ou de toute perte de donn&eacute;es pouvant r&eacute;sulter
                du t&eacute;l&eacute;chargement de ce mat&eacute;riel.
              </p>
              <p>
                <br />
              </p>
              <p>
                SECTION&nbsp;6 &ndash; EXACTITUDE DE LA FACTURATION ET DES
                INFORMATIONS DE COMPTE
              </p>
              <p>
                <br />
              </p>
              <p>
                Nous nous r&eacute;servons le droit de refuser toute commande
                que vous passez aupr&egrave;s de nous. Nous pouvons, &agrave;
                notre seule discr&eacute;tion, limiter ou annuler les
                quantit&eacute;s achet&eacute;es par personne, par foyer ou par
                commande. Ces restrictions peuvent inclure les commandes
                pass&eacute;es par ou sur le m&ecirc;me compte client, la
                m&ecirc;me carte de cr&eacute;dit et/ou des commandes utilisant
                la m&ecirc;me adresse de facturation et/ou
                d&apos;exp&eacute;dition. Si nous modifions ou annulons une
                commande, il se peut que nous tentions de vous en aviser en vous
                contactant au moyen de l&apos;adresse e-mail et/ou de
                l&apos;adresse de facturation ou du num&eacute;ro de
                t&eacute;l&eacute;phone fournis au moment de la commande.
                L&apos;utilisation de notre produit est strictement
                r&eacute;serv&eacute;e &agrave; des fins l&eacute;gales et
                conformes &agrave; nos politiques d&apos;utilisation. En cas
                d&apos;usage contraire &agrave; ces directives, nous nous
                r&eacute;servons le droit de suspendre imm&eacute;diatement et
                d&apos;interdire l&apos;acc&egrave;s &agrave; notre outil.
              </p>
              <p>
                Vous acceptez de fournir des informations d&apos;achat et de
                compte actuelles, compl&egrave;tes et exactes pour tous les
                achats effectu&eacute;s dans notre boutique. Vous acceptez de
                mettre rapidement &agrave; jour votre compte et toute autre
                information, y compris votre adresse e-mail et vos
                num&eacute;ros de cartes de cr&eacute;dit et leurs dates
                d&apos;expiration, afin que nous puissions finaliser vos
                transactions et vous contacter en cas de besoin.
              </p>
              <p>
                <br />
              </p>
              <p>
                Vous reconnaissez que toute personne utilisant une carte
                d&eacute;clare et garantit qu&apos;elle est autoris&eacute;e
                &agrave; utiliser ladite carte, et que toutes les charges
                peuvent &ecirc;tre factur&eacute;es sur cette carte et ne seront
                pas refus&eacute;es. Si Resell Corner ne peut pas traiter votre
                commande par carte pour quelque raison que ce soit, nous nous
                r&eacute;servons le droit de suspendre votre compte
                jusqu&apos;&agrave; ce que votre paiement puisse &ecirc;tre
                trait&eacute;. Vous acceptez que nous puissions tenter &agrave;
                nouveau le traitement du paiement d&egrave;s r&eacute;ception
                des informations de facturation mises &agrave; jour.
              </p>
              <p>
                Lors de la mise &agrave; niveau vers un abonnement de prix
                sup&eacute;rieur, cela autorise Resell Corner &agrave; facturer
                un montant au prorata au moment o&ugrave; la mise &agrave;
                niveau est effectu&eacute;e. Resell Corner se r&eacute;serve le
                droit de ne pas offrir de cr&eacute;dits si vous passez &agrave;
                un abonnement de prix inf&eacute;rieur. La facture suivant tout
                changement d&apos;abonnement refl&eacute;tera le nouveau montant
                de facturation.
              </p>
              <p>
                Vous pouvez annuler votre abonnement par e-mail en contactant
                contact@resellcorner.io depuis l&apos;adresse e-mail
                associ&eacute;e &agrave; votre compte Resell Corner. Les
                demandes d&apos;annulation doivent &ecirc;tre effectu&eacute;es
                24 heures avant la prochaine p&eacute;riode de facturation pour
                &eacute;viter des frais d&apos;abonnement
                suppl&eacute;mentaires. Les annulations apr&egrave;s la
                facturation n&apos;ouvrent pas droit &agrave; des
                remboursements.
              </p>
              <p>
                Pour plus d&apos;informations, veuillez consulter notre
                Politique de retour.
              </p>
              <p>
                <br />
              </p>
              <p>SECTION&nbsp;7 &ndash; OUTILS FACULTATIFS</p>
              <p>
                <br />
              </p>
              <p>
                Nous sommes susceptibles de vous fournir l&apos;acc&egrave;s
                &agrave; des outils tiers que nous ne surveillons, ne
                contr&ocirc;lons et ne g&eacute;rons pas.
              </p>
              <p>
                <br />
              </p>
              <p>
                Vous reconnaissez et acceptez que nous vous fournissons
                l&apos;acc&egrave;s &agrave; ces outils &laquo;&nbsp;tels
                quels&nbsp;&raquo; et &laquo;&nbsp;sous r&eacute;serve de
                disponibilit&eacute;&nbsp;&raquo;, sans garantie,
                repr&eacute;sentation ou condition d&apos;aucune sorte et sans
                la moindre approbation. Nous ne saurions &ecirc;tre tenus
                responsables de quoi que ce soit &agrave; l&apos;&eacute;gard de
                ce qui pourrait r&eacute;sulter de ou &ecirc;tre reli&eacute;
                &agrave; votre utilisation des outils facultatifs tiers.
              </p>
              <p>
                <br />
              </p>
              <p>
                Toute utilisation par vous des outils facultatifs
                propos&eacute;s par le biais du site est enti&egrave;rement
                &agrave; votre discr&eacute;tion et &agrave; vos propres
                risques. En outre, il vous appartient de vous renseigner sur les
                conditions dans lesquelles ces outils sont fournis par le(s)
                fournisseur(s) tiers concern&eacute;(s) et accepter ces
                conditions.
              </p>
              <p>
                <br />
              </p>
              <p>
                Il se peut &eacute;galement qu&apos;&agrave; l&apos;avenir, nous
                proposions de nouveaux services et/ou de nouvelles
                fonctionnalit&eacute;s &agrave; travers le site web (y compris
                le lancement de nouveaux outils et ressources). Ces nouveaux
                services et/ou fonctionnalit&eacute;s seront aussi assujettis
                aux pr&eacute;sentes Conditions d&apos;utilisation.
              </p>
              <p>
                <br />
              </p>
              <p>ARTICLE&nbsp;8 &ndash; LIENS DE TIERS</p>
              <p>
                <br />
              </p>
              <p>
                Certains contenus, produits et services accessibles via notre
                Service peuvent inclure des &eacute;l&eacute;ments provenant de
                tiers.
              </p>
              <p>
                <br />
              </p>
              <p>
                Les liens de tiers sur ce site peuvent vous rediriger vers des
                sites web de tiers qui ne sont pas affili&eacute;s &agrave;
                nous. Nous ne sommes pas tenus d&rsquo;examiner ou
                d&rsquo;&eacute;valuer leur contenu ou leur exactitude, de
                m&ecirc;me que nous ne garantissons pas et n&rsquo;assumons
                aucune responsabilit&eacute; quant aux contenus ou sites web, ou
                aux autres contenus, produits ou services de sources tierces.
              </p>
              <p>
                <br />
              </p>
              <p>
                Nous ne sommes pas responsables des pr&eacute;judices ou
                dommages li&eacute;s &agrave; l&rsquo;achat ou &agrave;
                l&rsquo;utilisation de biens, services, ressources, contenus ou
                de toute autre transaction reli&eacute;e &agrave; ces sites web
                tiers. Veuillez lire attentivement les politiques et pratiques
                de ces tiers et assurez-vous de bien les comprendre avant de
                vous engager dans une transaction. Les plaintes,
                r&eacute;clamations, pr&eacute;occupations ou questions
                concernant les produits de tiers doivent &ecirc;tre
                adress&eacute;es &agrave; ces m&ecirc;mes tiers.
              </p>
              <p>
                <br />
              </p>
              <p>
                ARTICLE&nbsp;9 &ndash; COMMENTAIRES, RETOURS
                D&apos;EXP&Eacute;RIENCE ET AUTRES SOUMISSIONS
              </p>
              <p>
                <br />
              </p>
              <p>
                Si, &agrave; notre demande, vous soumettez des contenus
                sp&eacute;cifiques (par exemple, dans le cadre de votre
                participation &agrave; des concours), ou si, sans demande de
                notre part, vous envoyez des id&eacute;es cr&eacute;atives, des
                suggestions, des propositions, des plans ou d&rsquo;autres
                &eacute;l&eacute;ments, que ce soit en ligne, par e-mail, par
                courrier ou autrement (collectivement,
                &laquo;&nbsp;commentaires&nbsp;&raquo;), vous nous accordez le
                droit, &agrave; tout moment et sans restriction, de modifier,
                copier, publier, distribuer, traduire et utiliser dans quelque
                m&eacute;dia que ce soit tous les commentaires que vous nous
                transmettez. Nous ne sommes pas et ne devrons en aucun cas
                &ecirc;tre tenus (1) de maintenir la confidentialit&eacute; des
                commentaires&nbsp;; (2) de d&eacute;dommager qui que ce soit
                pour tout commentaire fourni&nbsp;; ou (3) de r&eacute;pondre
                aux commentaires.
              </p>
              <p>
                7. Nous pouvons, mais nous n&apos;en avons pas
                l&apos;obligation, supprimer le contenu et les Comptes contenant
                du contenu que nous jugeons, &agrave; notre seule
                discr&eacute;tion, ill&eacute;gal, offensant, mena&ccedil;ant,
                diffamatoire, pornographique, obsc&egrave;ne ou autrement
                r&eacute;pr&eacute;hensible ou qui viole la
                propri&eacute;t&eacute; intellectuelle d&apos;une partie ou les
                pr&eacute;sentes Conditions d&apos;utilisation.
              </p>
              <p>
                Vous convenez que vos commentaires ne doivent en aucun cas
                porter atteinte aux droits de tiers, y compris aux droits
                d&apos;auteur, aux marques de commerce, &agrave; la vie
                priv&eacute;e, &agrave; la personnalit&eacute; ou &agrave; tout
                autre droit personnel ou de propri&eacute;t&eacute;
                intellectuelle. Vous convenez en outre que vos commentaires ne
                devront contenir aucun &eacute;l&eacute;ment ill&eacute;gal,
                injurieux ou obsc&egrave;ne, ni aucun virus informatique ou
                autre logiciel malveillant susceptible d&apos;affecter
                d&apos;une quelconque fa&ccedil;on le fonctionnement du Service
                ou de tout site web connexe. Vous ne pouvez pas utiliser de
                fausse adresse e-mail, pr&eacute;tendre &ecirc;tre
                quelqu&rsquo;un que vous n&rsquo;&ecirc;tes pas, ou essayer de
                nous induire, nous ou les tiers, en erreur quant &agrave;
                l&rsquo;origine des commentaires. Vous &ecirc;tes
                enti&egrave;rement responsable de tous les commentaires que vous
                &eacute;mettez ainsi que de leur exactitude. Nous
                d&eacute;clinons toute responsabilit&eacute; &agrave;
                l&apos;&eacute;gard des commentaires publi&eacute;s par vous ou
                un tiers.
              </p>
              <p>
                <br />
              </p>
              <p>ARTICLE&nbsp;10 &ndash; INFORMATIONS PERSONNELLES</p>
              <p>
                <br />
              </p>
              <p>
                La transmission de vos informations personnelles sur notre site
                est r&eacute;gie par notre Politique de confidentialit&eacute;.
                &nbsp;Cliquez ici pour consulter notre Politique de
                Confidentialit&eacute;.
              </p>
              <p>
                <br />
              </p>
              <p>ARTICLE&nbsp;11 &ndash; ERREURS, INEXACTITUDES ET OMISSIONS</p>
              <p>
                <br />
              </p>
              <p>
                Il se peut qu&apos;il y ait parfois, sur notre site ou dans le
                Service, des informations contenant des erreurs typographiques,
                des inexactitudes ou des omissions reli&eacute;es aux
                descriptions, aux prix, aux promotions, aux offres, aux frais
                d&rsquo;exp&eacute;dition, aux d&eacute;lais d&apos;acheminement
                et &agrave; la disponibilit&eacute; des produits. Nous nous
                r&eacute;servons le droit de corriger toute erreur, inexactitude
                ou omission, et de changer ou d&apos;actualiser des
                informations, voire d&rsquo;annuler des commandes si une
                quelconque information dans le Service ou sur tout site web
                connexe est inexacte, et ce, &agrave; tout moment et sans
                pr&eacute;avis (y compris apr&egrave;s que vous ayez
                pass&eacute; votre commande).
              </p>
              <p>
                <br />
              </p>
              <p>
                Nous ne sommes pas tenus d&apos;actualiser, de modifier ou de
                clarifier les informations indiqu&eacute;es dans le Service ou
                sur tout site web connexe, y compris mais sans s&apos;y limiter,
                les informations sur les prix, sauf si la loi l&apos;exige.
                Aucune date pr&eacute;cise de mise &agrave; jour ou
                d&rsquo;actualisation appliqu&eacute;e au Service ou &agrave;
                tout site web connexe ne saurait &ecirc;tre d&eacute;finie pour
                indiquer que l&apos;ensemble des informations offertes dans le
                Service ou sur tout site web connexe ont &eacute;t&eacute;
                modifi&eacute;es ou mises &agrave; jour.
              </p>
              <p>
                <br />
              </p>
              <p>ARTICLE&nbsp;12 &ndash; UTILISATIONS INTERDITES</p>
              <p>
                <br />
              </p>
              <p>
                En plus des autres interdictions &eacute;nonc&eacute;es dans les
                Conditions d&rsquo;utilisation, il vous est interdit
                d&rsquo;utiliser le site ou son contenu&nbsp;:
              </p>
              <p>
                (a) &agrave; des fins ill&eacute;gales&nbsp;; (b) pour inciter
                des tiers &agrave; r&eacute;aliser des actes ill&eacute;gaux ou
                &agrave; y prendre part&nbsp;; (c) pour enfreindre toute
                ordonnance locale ou toute r&eacute;glementation, r&egrave;gle
                ou loi internationale, f&eacute;d&eacute;rale, provinciale ou
                &eacute;tatique&nbsp;; (d) pour transgresser ou violer nos
                droits de propri&eacute;t&eacute; intellectuelle ou ceux de
                tiers&nbsp;; (e) pour harceler, maltraiter, insulter, blesser,
                diffamer, calomnier, d&eacute;nigrer, intimider ou discriminer
                quiconque en fonction du sexe, de l&rsquo;orientation sexuelle,
                de la religion, de l&rsquo;origine ethnique, de la race, de
                l&rsquo;&acirc;ge, de l&rsquo;origine nationale ou d&rsquo;un
                handicap&nbsp;; (f) pour soumettre des renseignements faux ou
                trompeurs&nbsp;;
              </p>
              <p>
                (g) pour mettre en ligne ou transmettre des virus ou tout autre
                type de code malveillant qui sera ou pourrait &ecirc;tre
                utilis&eacute; en vue de compromettre la fonctionnalit&eacute;
                ou le fonctionnement du Service ou de tout site web connexe,
                ainsi que d&apos;autres sites web ou d&rsquo;Internet&nbsp;; (h)
                pour recueillir ou suivre les renseignements personnels
                d&rsquo;autrui&nbsp;; (i) pour spammer, hame&ccedil;onner,
                d&eacute;tourner un domaine, extorquer des informations,
                parcourir, explorer ou balayer le web&nbsp;; (j) &agrave; des
                fins obsc&egrave;nes ou immorales&nbsp;; ou (k) pour perturber
                ou contourner les mesures de s&eacute;curit&eacute; du Service
                ou de tout site connexe, ainsi que d&apos;autres sites web ou
                d&rsquo;Internet. Nous nous r&eacute;servons le droit de mettre
                fin &agrave; votre utilisation du Service ou de tout site web
                connexe pour avoir enfreint les interdits en mati&egrave;re
                d&apos;utilisation.
              </p>
              <p>
                <br />
              </p>
              <p>
                ARTICLE&nbsp;13 &ndash; EXCLUSION DE GARANTIES ET LIMITATION DE
                RESPONSABILIT&Eacute;
              </p>
              <p>
                <br />
              </p>
              <p>
                Nous ne garantissons, certifions ou d&eacute;clarons en aucun
                cas que votre utilisation de notre Service sera ininterrompue,
                s&eacute;curis&eacute;e, sans d&eacute;lai ou sans erreur.
              </p>
              <p>
                <br />
              </p>
              <p>
                Nous ne garantissons pas que les r&eacute;sultats qui pourraient
                &ecirc;tre obtenus en utilisant le Service seront exacts ou
                fiables.
              </p>
              <p>
                <br />
              </p>
              <p>
                Vous acceptez que, de temps &agrave; autre, nous puissions
                retirer le Service pour des p&eacute;riodes
                ind&eacute;termin&eacute;es ou l&apos;annuler &agrave; tout
                moment et sans pr&eacute;avis.
              </p>
              <p>
                Vous convenez express&eacute;ment que votre utilisation du
                Service, ou votre incapacit&eacute; &agrave; utiliser celui-ci,
                est &agrave; votre seul risque. Le Service ainsi que tous les
                produits et services qui vous sont fournis par le biais de
                celui-ci sont (sauf mention expresse de notre part) fournis
                &laquo;&nbsp;tels quels&nbsp;&raquo; et &laquo;&nbsp;sous
                r&eacute;serve de disponibilit&eacute;&nbsp;&raquo; pour votre
                utilisation, et ce, sans repr&eacute;sentation, garanties ou
                conditions d&apos;aucune sorte, soit expresses soit implicites,
                y compris toutes les garanties ou conditions implicites de
                commercialisation ou de qualit&eacute; marchande,
                d&rsquo;adaptation &agrave; un usage particulier, de
                durabilit&eacute;, de titre et d&rsquo;absence de
                contrefa&ccedil;on.
              </p>
              <p>
                ResellCorner, nos directeurs, responsables, employ&eacute;s,
                soci&eacute;t&eacute;s affili&eacute;es, agents, contractants,
                stagiaires, fournisseurs, prestataires de services et
                conc&eacute;dants ne peuvent en aucun cas &ecirc;tre tenus
                responsables de toute blessure, perte, r&eacute;clamation, ou de
                quelconques dommages directs, indirects, accessoires, punitifs,
                sp&eacute;ciaux ou cons&eacute;cutifs, y compris mais sans
                s&apos;y limiter, de la perte de profits, revenus,
                &eacute;conomies ou donn&eacute;es, de co&ucirc;ts de
                remplacement ou autres dommages similaires, qu&rsquo;ils soient
                contractuels, d&eacute;lictuels (m&ecirc;me en cas de
                n&eacute;gligence), de responsabilit&eacute; stricte ou autre,
                r&eacute;sultant de votre utilisation du Service ou de tout
                service ou produit recourant &agrave; celui-ci, ou de toute
                autre r&eacute;clamation li&eacute;e de quelque mani&egrave;re
                que ce soit &agrave; votre utilisation du Service ou de tout
                produit, y compris mais sans s&apos;y limiter, &agrave; des
                erreurs ou omissions dans un contenu, ou &agrave; de quelconques
                pertes ou dommages d&eacute;coulant de l&rsquo;utilisation du
                Service ou d&apos;un contenu (ou produit) publi&eacute;,
                transmis ou rendu accessible par le biais du Service, et ce,
                m&ecirc;me si vous avez &eacute;t&eacute; averti(e) de la
                possibilit&eacute; qu&rsquo;ils surviennent.
              </p>
              <p>
                Du fait que certains &Eacute;tats ou juridictions ne permettent
                pas d&rsquo;exclure ou de limiter la responsabilit&eacute; quant
                aux dommages cons&eacute;cutifs ou accessoires, notre
                responsabilit&eacute; dans ces &Eacute;tats ou juridictions sera
                limit&eacute;e dans la mesure maximale permise par la loi.
              </p>
              <p>
                <br />
              </p>
              <p>ARTICLE&nbsp;14 &ndash; INDEMNISATION</p>
              <p>
                <br />
              </p>
              <p>
                Vous acceptez d&rsquo;indemniser, de d&eacute;fendre et de tenir
                ResellCorner et notre soci&eacute;t&eacute; m&egrave;re, nos
                filiales, soci&eacute;t&eacute;s affili&eacute;es, partenaires,
                responsables, directeurs, agents, contractants,
                conc&eacute;dants, prestataires de services, sous-traitants,
                fournisseurs, stagiaires et employ&eacute;s, quittes de toute
                r&eacute;clamation ou demande, y compris d&apos;honoraires
                raisonnables d&rsquo;avocat, &eacute;mise par un quelconque
                tiers &agrave; cause de ou cons&eacute;cutivement &agrave; votre
                violation des pr&eacute;sentes Conditions d&rsquo;utilisation ou
                des documents auxquels elles font r&eacute;f&eacute;rence, ou
                &agrave; votre violation de quelconques lois ou droits
                d&rsquo;un tiers.
              </p>
              <p>
                <br />
              </p>
              <p>ARTICLE&nbsp;15 &ndash; DISSOCIABILIT&Eacute;</p>
              <p>
                <br />
              </p>
              <p>
                Dans le cas o&ugrave; une quelconque disposition des
                pr&eacute;sentes Conditions d&rsquo;utilisation est jug&eacute;e
                ill&eacute;gale, nulle ou inapplicable, cette disposition sera
                n&eacute;anmoins applicable dans la pleine mesure permise par la
                loi, et la partie non applicable sera consid&eacute;r&eacute;e
                comme &eacute;tant dissoci&eacute;e de ces Conditions
                d&rsquo;utilisation, sans que ce jugement n&apos;affecte la
                validit&eacute; et l&rsquo;applicabilit&eacute; des autres
                dispositions.
              </p>
              <p>
                <br />
              </p>
              <p>ARTICLE&nbsp;16 &ndash; R&Eacute;SILIATION</p>
              <p>
                <br />
              </p>
              <p>
                Les obligations et responsabilit&eacute;s engag&eacute;es par
                les parties avant la date de r&eacute;siliation resteront en
                vigueur apr&egrave;s la r&eacute;siliation de cet accord, et ce,
                &agrave; toutes fins.
              </p>
              <p>
                <br />
              </p>
              <p>
                Les pr&eacute;sentes Conditions d&rsquo;utilisation resteront en
                vigueur, &agrave; moins et jusqu&rsquo;&agrave; ce
                qu&rsquo;elles soient r&eacute;sili&eacute;es par vous ou par
                nous. Vous pouvez r&eacute;silier ces Conditions
                d&rsquo;utilisation &agrave; tout moment en nous avisant que
                vous ne souhaitez plus utiliser nos Services, ou lorsque vous
                cessez d&rsquo;utiliser notre site.
              </p>
              <p>
                Si nous jugeons ou suspectons, &agrave; notre seule
                discr&eacute;tion, que vous ne respectez pas ou que vous
                n&apos;avez pas respect&eacute; une quelconque modalit&eacute;
                ou disposition des pr&eacute;sentes Conditions
                d&rsquo;utilisation, nous pouvons &eacute;galement
                r&eacute;silier cet accord &agrave; tout moment et sans
                pr&eacute;avis. Vous demeurerez alors responsable de toutes les
                sommes redevables jusqu&rsquo;&agrave; la date de
                r&eacute;siliation (incluse), en cons&eacute;quence de quoi nous
                pouvons vous refuser l&rsquo;acc&egrave;s &agrave; nos Services
                (ou &agrave; une partie de ceux-ci).
              </p>
              <p>
                <br />
              </p>
              <p>
                ARTICLE&nbsp;17 &ndash; INT&Eacute;GRALIT&Eacute; DE
                L&rsquo;ACCORD
              </p>
              <p>
                <br />
              </p>
              <p>
                Tout manquement de notre part &agrave; l&rsquo;exercice ou
                &agrave; l&rsquo;application d&apos;un droit ou d&apos;une
                disposition des pr&eacute;sentes Conditions d&rsquo;utilisation
                ne constitue pas une renonciation &agrave; ce droit ou &agrave;
                cette disposition.
              </p>
              <p>
                <br />
              </p>
              <p>
                Les pr&eacute;sentes Conditions d&rsquo;utilisation ou toute
                autre politique ou r&egrave;gle d&rsquo;exploitation que nous
                publions sur ce site ou qui concernent le Service constituent
                l&rsquo;int&eacute;gralit&eacute; de l&rsquo;entente et de
                l&rsquo;accord entre vous et nous, et r&eacute;gissent votre
                utilisation du Service. Elles remplacent l&apos;ensemble des
                accords, communications et propositions ant&eacute;rieurs et
                actuels, oraux ou &eacute;crits, entre vous et nous (y compris,
                mais sans s&apos;y limiter, toute version ant&eacute;rieure des
                Conditions d&rsquo;utilisation).
              </p>
              <p>
                Toute ambigu&iuml;t&eacute; quant &agrave;
                l&rsquo;interpr&eacute;tation de ces Conditions
                d&rsquo;utilisation ne doit pas &ecirc;tre
                interpr&eacute;t&eacute;e en d&eacute;faveur de la partie
                r&eacute;dactrice.
              </p>
              <p>
                <br />
              </p>
              <p>
                <br />
              </p>
              <p>ARTICLE&nbsp;18 &ndash; LOI APPLICABLE</p>
              <p>
                <br />
              </p>
              <p>
                Les pr&eacute;sentes Conditions d&rsquo;utilisation, ainsi que
                tout accord distinct par lequel nous vous fournissons les
                Services sont r&eacute;gis et interpr&eacute;t&eacute;s en vertu
                des lois Fran&ccedil;aises.&nbsp;
              </p>
              <p>
                <br />
              </p>
              <p>
                <br />
              </p>
              <p>
                ARTICLE&nbsp;19 &ndash; MODIFICATIONS APPORT&Eacute;ES AUX
                CONDITIONS D&rsquo;UTILISATION
              </p>
              <p>
                <br />
              </p>
              <p>
                Vous pouvez consulter la version la plus r&eacute;cente des
                Conditions d&rsquo;utilisation &agrave; tout moment sur cette
                page.
              </p>
              <p>
                <br />
              </p>
              <p>
                Nous nous r&eacute;servons le droit, &agrave; notre seule
                discr&eacute;tion, de mettre &agrave; jour, modifier ou
                remplacer toute partie des pr&eacute;sentes Conditions
                d&apos;utilisation en publiant lesdites mises &agrave; jour
                et/ou modifications sur notre site web. Il vous incombe de
                v&eacute;rifier notre site web de temps &agrave; autre pour voir
                si des changements y ont &eacute;t&eacute; apport&eacute;s. En
                continuant &agrave; acc&eacute;der &agrave; notre site web et au
                Service ou &agrave; les utiliser apr&egrave;s la publication de
                modifications apport&eacute;es aux pr&eacute;sentes Conditions
                d&apos;utilisation, vous acceptez celles-ci.
              </p>
              <p>
                <br />
              </p>
              <p>
                <br />
              </p>
              <p>ARTICLE&nbsp;20 &ndash; COORDONN&Eacute;ES</p>
              <p>
                <br />
              </p>
              <p>
                Les questions relatives aux Conditions d&rsquo;utilisation
                doivent nous &ecirc;tre envoy&eacute;es &agrave;
                contact@resellcorner.io.
              </p>
              <p>
                <br />
              </p>
              <p>
                <br />
              </p>
              <p>Mise &agrave; jour 01/01/2024.</p>
              <p>
                <br />
              </p>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default IndexMain;
